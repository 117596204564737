/*eslint-disable*/
import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Select from 'react-select'
import commaNumber from "comma-number";

const Customers = ({ xls, icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer") {
        window.location.assign('/')
    }
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get("/customer/list");
        setData(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 2, 3]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: [0]
                                },

                            ]
                        })
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };


    const [customerGroup, setCustomerGroup] = useState([]);
    const [selectGroup, setSelectGroup] = useState([]);
    const getCustomerGroup = async () => {
        const { data } = await axios.get("/customer/group");
        setCustomerGroup(data);
        setSelectGroup(data.map((item) => {
            return {
                value: item.id,
                label: item[`group_name_${lang}`]
            };
        }));
    };

    const [currency, setCurrency] = useState([]);
    const getCurrency = async () => {
        const { data } = await axios.get('/currency');
        setCurrency(data.map((item) => {
            return {
                value: item.id,
                label: `${item.symbol} (${translate(`price_${lang}`)} ${item.rate})`,
                rate: item.rate,
            };
        }));
    }

    useEffect(() => {
        getData();
        getCurrency();
        getCustomerGroup();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    const [orders, setorders] = useState([])
    const [companyPayment, setcompanyPayment] = useState([])
    const getCompany = async (id) => {
        const { data: orders } = await axios.get(`/order/company/${id}`);
        const { data: payment } = await axios.get(`/order/company/payment/${id}`);
        setorders(orders), setcompanyPayment(payment)
        if (orders.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.company-order')) {
                        window.$('.company-order').DataTable();
                    } else {
                        window.$('.company-order').DataTable({
                            responsive: true,
                        })
                    }
                }
            })
        }
        if (payment.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.company-payment')) {
                        window.$('.company-payment').DataTable();
                    } else {
                        window.$('.company-payment').DataTable({
                            responsive: true,
                        })
                    }
                }
            })
        }
    }

    const [sales, setsales] = useState([])
    const [buyerPayment, setbuyerPayment] = useState([])
    const getBuyer = async (id) => {
        const { data: sales } = await axios.get(`/sale/buyer/${id}`);
        const { data: payment } = await axios.get(`/sale/buyer/payment/${id}`);
        setsales(sales), setbuyerPayment(payment)
        if (sales.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.buyer-sales')) {
                        window.$('.buyer-sales').DataTable();
                    } else {
                        window.$('.buyer-sales').DataTable({
                            responsive: true,
                        })
                    }
                }
            })
        }
        if (payment.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.buyer-payment')) {
                        window.$('.buyer-payment').DataTable();
                    } else {
                        window.$('.buyer-payment').DataTable({
                            responsive: true,
                        })
                    }
                }
            })
        }
    }

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`accounts_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`cusotmer_list_${lang}`)}</h6>
                                </div>
                                {(role === "admin" || role === "superuser") && (
                                    <div className="col-md-6 d-flex justify-content-end"> 
                                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#new">
                                            <icon.UserPlus size="20" />
                                            <span className="ms-2">{translate(`new_account_${lang}`)}</span>
                                        </button>
                                    </div>
                                )} 
                                <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title"> {translate(`new_account_${lang}`)} </h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                            </div>
                                            <form className="form-sample" onSubmit={(e) => {
                                                e.preventDefault();
                                                const formData = new FormData(e.target);
                                                const data = Object.fromEntries(formData);
                                                axios.post(`/customer/add`, data).then(res => {
                                                    if (isNaN(res.data)) {
                                                        toast.error(res.data, {
                                                            position: "top-left",
                                                            autoClose: 3000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            progress: undefined,
                                                        });
                                                    } else {
                                                        getData();
                                                        e.target.reset();
                                                        window.$('#new').modal('hide');
                                                    }
                                                });
                                            }}>
                                                <div className="modal-body">
                                                    
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="name" className="form-label"> {translate(`name_${lang}`)} ({translate("kurdi")})<span className="text-danger">*</span></label>
                                                                <input type="text" className="form-control" name="full_name" placeholder={translate(`name_${lang}`)} required />
                                                            </div>
                                                        </div> 
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="address" className="form-label">{translate(`address_${lang}`)}  ({translate("kurdi")})<span className="text-danger">*</span></label>
                                                                <input type="text" className="form-control" name="address" placeholder={translate(`address_${lang}`)} required />
                                                            </div>
                                                        </div> 
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="phone" className="form-label">{translate(`phone_no_${lang}`)} <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control" name="phone_number" placeholder={translate(`phone_no_${lang}`)} required />
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                    <button type="submit" className="btn btn-primary"> {translate(`add_${lang}`)}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-sm dt-tbl print mb-3" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>  
                                        <th>{translate(`name_${lang}`)}</th>
                                        <th>{translate(`phone_no_${lang}`)}</th>
                                        <th>{translate(`address_${lang}`)}</th>
                                        <th>{translate(`date_${lang}`)}</th>
                                        <th>{translate(`options_${lang}`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td> 
                                            <td>{item.full_name}</td>
                                            <td>{item.phone_number}</td>
                                            <td>{item.address}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                {role === "admin" || role === "superuser" &&
                                                    <>

                                                        <span type="button" className="badge bg-danger" onClick={() => {
                                                            Swal.fire({
                                                                title: translate(`warning_${lang}`),
                                                                text: translate(`warning_text_${lang}`),
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: translate(`yes_${lang}`),
                                                                cancelButtonText: translate(`no_${lang}`)
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    axios.delete(`/customer/delete/${item.id}`).then(res => {
                                                                        !isNaN(res.data) ? getData() : toast.error(res.data);
                                                                    })
                                                                }
                                                            })
                                                        }} >
                                                            <icon.Trash size={16} />
                                                        </span>
                                                    </>
                                                }
                                                <span type="button" className="badge bg-warning mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                    <icon.Edit size={16} />
                                                </span>
                                                {item.customer_type === "buyer" ?
                                                    <span type="button" className="badge bg-dark mx-1" data-bs-toggle="modal" data-bs-target={`#inv${item.id}`}>
                                                        <icon.FileText size={16} />
                                                    </span>
                                                    : null
                                                }
                                                {item.customer_type === "buyer" ?
                                                    <span type="button" className="badge bg-primary mx-1" data-bs-toggle="modal" data-bs-target={`#printer${item.id}`}>
                                                        <icon.Printer size={16} />
                                                    </span> :
                                                    <a href={`/company/invoice/${item.id}`} className="badge bg-primary mx-1">
                                                        <icon.Printer size={16} />
                                                    </a>
                                                } 
                                                <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby={`edit${item.id}`} aria-hidden="true">
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">{translate(`edit_${lang}`)}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/customer/update/${item.id}`, data).then(res => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        e.target.reset();
                                                                        getData();
                                                                        window.$(`#edit${item.id}`).modal('hide');
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body"> 
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="name" className="form-label">{translate(`name_${lang}`)} ({translate("kurdi")})<span className="text-danger">*</span></label>
                                                                                <input type="text" className="form-control" name="full_name" placeholder={translate(`name_${lang}`)} defaultValue={item.full_name} required />
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="address" className="form-label">{translate(`address_${lang}`)} ({translate("kurdi")})<span className="text-danger">*</span></label>
                                                                                <input type="text" className="form-control" name="address" placeholder={translate(`address_${lang}`)} defaultValue={item.address} required />
                                                                            </div>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="phone" className="form-label">{translate(`phone_no_${lang}`)}<span className="text-danger">*</span></label>
                                                                        <input type="text" className="form-control" name="phone_number" placeholder={translate(`phone_no_${lang}`)} defaultValue={item.phone_number} required />
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                    <button type="submit" className="btn btn-primary">{translate(`update_${lang}`)}</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal fade" id={`inv${item.id}`} tabIndex={-1} aria-labelledby={`inv${item.id}`} aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title">{translate(`custom_inv_${lang}`)}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.post(`/customer/custom-inv`, data).then(res => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        e.target.reset();
                                                                        getData();
                                                                        toast.success(translate(`success_${lang}`))
                                                                        window.$(`#inv${item.id}`).modal('hide');
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body">
                                                                    <div className="row">
                                                                        <div className="col-md-6 mb-3">
                                                                            <label className="form-label"> {translate(`date_${lang}`)}</label>
                                                                            <input type="date" className="form-control" name="created" defaultValue={new Date().toISOString().split('T')[0]} />
                                                                        </div>
                                                                        <div className="col-md-6 mb-3">
                                                                            <label className="form-label"> {translate(`currency_${lang}`)}</label>
                                                                            <Select options={currency} name="currency_id" placeholder={translate(`currency_${lang}`)} required onChange={(e) => {
                                                                                $('input[name="rate"]').val(e.rate);
                                                                                $('#crc').text(e.label)
                                                                            }} />
                                                                            <input type="hidden" name="rate" required />
                                                                        </div>
                                                                        <div className="col-md-6 mb-3">
                                                                            <label className="form-label"> {translate(`amount_${lang}`)}</label>
                                                                            <input type="number" className="form-control" id="amount" name="amount" placeholder="0.00" />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="type" className="form-label">{translate(`invoice_type_${lang}`)}</label>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="radio" name="inv_type" id="type1" defaultValue={'income'} defaultChecked />
                                                                                    <label className="form-check-label" htmlFor="type1">{translate(`income_${lang}`)}</label>
                                                                                </div>
                                                                                <div className="form-check form-check-inline">
                                                                                    <input className="form-check-input" type="radio" name="inv_type" id="type2" defaultValue={'outcome'} />
                                                                                    <label className="form-check-label" htmlFor="type2">{translate(`outcome_${lang}`)}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12 mb-3">
                                                                            <label className="form-label"> {translate(`note_${lang}`)}</label>
                                                                            <textarea className="form-control" name="note" placeholder={translate(`note_${lang}`)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                    <button type="submit" className="btn btn-primary">{translate(`add_${lang}`)}</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table> 
                            <button className="btn btn-light btn4xls" onClick={xls}>{translate(`ex2excel_${lang}`)}</button>
                            <button className="btn btn-light btn4pdf mx-2" onClick={() => window.print()}>{translate(`ex2pdf_${lang}`)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Customers;