import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import commaNumber from 'comma-number';
import axios from "axios";
import $ from "jquery";

const SellerInvoice = ({ translate, lang, role }) => {
    const customer_id = useParams().id

    const [sale, setSale] = useState([]);
    const [info, setInfo] = useState({});
    const getSale = async () => {
        const { data } = await axios.get(`/sale/summery/${customer_id}`);
        setSale(data);
        const { data: info } = await axios.get(`/sale/info/${customer_id}`);
        setInfo(info);
    }

    useEffect(() => {
        getSale();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        $('.py-4').hide();
        $('.dt-tbl').show();
        // eslint-disable-next-line
    }, [customer_id]);

    const totalsByCurrency = sale.reduce((totals, item) => {
        const symbol = item.symbol;
        const total = item.total;
        const discount = item.discount;
        const amount_paid = item.amount_paid;
        const amount_due = item.amount_due;
        if (totals.hasOwnProperty(symbol)) {
            totals[symbol].total += total;
            totals[symbol].discount += discount;
            totals[symbol].amount_paid += amount_paid;
            totals[symbol].amount_due += amount_due;
        } else {
            totals[symbol] = {
                total: total,
                discount: discount,
                amount_paid: amount_paid,
                amount_due: amount_due
            };
        }
        return totals;
    }, {});


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`invoice_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        {sale.length ?
                            <div className="card-body print">
                                <div className="container-fluid d-flex justify-content-between">
                                    <div className="col-lg-3 ps-0">
                                        <a href="/" className="noble-ui-logo d-block mt-3">Lion<span>Land Co.</span></a>
                                        <p className="mt-1 mb-1">{translate(`company_name_${lang}`)}<br /> <b>{translate(`company_text_${lang}`)}</b></p>
                                        <small className="text-muted">{translate(`address_${lang}`)}: {translate(`company_address_${lang}`)}</small>
                                        <h5 className="mt-5 mb-2 text-muted">{translate(`buyer_${lang}`)}</h5>
                                        <p>{info[`customer_name_${lang}`]}</p>
                                    </div>
                                    <div className="col-lg-3 pe-0">
                                        <h4 className="fw-bolder text-uppercase text-end mt-4 mb-2">{translate(`invoice_${lang}`)}</h4>
                                        <h6 className="text-end mb-5 pb-4">{translate(`invoice_no_${lang}`)}-{info.id}</h6>
                                        <p className="text-end mb-1">{translate(`total_${lang}`)}</p>
                                        <h4 className="text-end fw-normal">{commaNumber(info.total)} {info.symbol}</h4>
                                        <h6 className="mb-0 mt-3 text-end fw-normal mb-2"><span className="text-muted">{translate(`date_${lang}`)} :</span> {new Date(info.created).toLocaleDateString()}</h6>
                                    </div>
                                </div>
                                <div className="container-fluid mt-5 w-100">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>{translate(`invoice_${lang}`)}</th>
                                                    <th>{translate(`total_price_${lang}`)}</th>
                                                    <th>{translate(`discount_${lang}`)}</th>
                                                    <th>{translate(`paid_${lang}`)}</th>
                                                    <th>{translate(`due_${lang}`)}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sale.map((item, index) => (

                                                    <tr key={index}>
                                                        <td>
                                                            <a href={`/sale/invoice/${item.id}`} target={'_blank'} rel="noreferrer"> {item.id} </a>
                                                        </td>
                                                        <td>{commaNumber(item.total)}</td>
                                                        <td>{commaNumber(item.discount)}</td>
                                                        <td>{commaNumber(item.amount_paid)}  {item.symbol}</td>
                                                        <td>{commaNumber(item.amount_due)}  {item.symbol}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="container-fluid mt-5 w-100">
                                    <div className="row">
                                        <div className="col-xl-6 ms-auto px-5 mt-4">
                                            <div className="table-responsive">
                                                <table className="table table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-bold-800">{translate(`total_${lang}`)}</td>
                                                            {Object.entries(totalsByCurrency).map(([symbol, totals]) => (
                                                                <td key={symbol} className="text-bold-800 text-end">
                                                                    {commaNumber(totals.total)} {symbol}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr className="bg-light">
                                                            <td className="text-bold-800">{translate(`discount_${lang}`)}</td>
                                                            {Object.entries(totalsByCurrency).map(([symbol, totals]) => (
                                                                <td key={symbol} className="text-bold-800 text-end">
                                                                    {commaNumber(totals.discount)} {symbol}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-bold-800"> {translate(`paid_${lang}`)}</td>
                                                            {Object.entries(totalsByCurrency).map(([symbol, totals]) => (
                                                                <td key={symbol} className="text-bold-800 text-end">
                                                                    {commaNumber(totals.amount_paid)} {symbol}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr className="bg-dark text-white">
                                                            <td className="text-bold-800">{translate(`due_${lang}`)}</td>
                                                            {Object.entries(totalsByCurrency).map(([symbol, totals]) => (
                                                                <td key={symbol} className="text-bold-800 text-end">
                                                                    {commaNumber(totals.amount_due)} {symbol}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </div >
        </div >
    );
}



export default SellerInvoice;