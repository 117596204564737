/* eslint-disable */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";

const Navbar = ({ icon, translate, lang, role }) => {
    const navigate = useNavigate();
    const logout = () => {
        axios.post('/logout').then((ok) => {
            if (ok.data === 'done') {
                navigate('/login')
            }
        })
    }
    const [nav, setNav] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    useEffect(() => {
        axios.get('/info').then((res) => {
            setUserInfo(
                <div className="dropdown-menu p-0" aria-labelledby="profileDropdown">
                    <div className="d-flex flex-column align-items-center border-bottom px-5 py-3">
                        <div className="mb-3">
                            <icon.User size={20} />
                        </div>
                        <div className="text-center">
                            <p className="tx-16 fw-bolder">{res.data.username}</p>
                            <p className="tx-16 text-muted">{res.data.role}#</p>
                        </div>
                    </div>
                    <ul className="list-unstyled p-1">
                        {res.data.role === "admin" &&
                            <>
                                <li className="dropdown-item py-2">
                                    <Link to="/currencies" className="text-body ms-0">
                                        <icon.DollarSign size={16} />
                                        <span className="mx-2">{translate(`exchange_${lang}`)}</span>
                                    </Link>
                                </li>
                                <li className="dropdown-item py-2">
                                    <Link to="/accounts" className="text-body ms-0">
                                        <icon.Users size={16} />
                                        <span className="mx-2">{translate(`accounts_${lang}`)}</span>
                                    </Link>
                                </li>
                               
                            </>
                        }
                        <li className="dropdown-item py-2">
                            <p type="button" className="text-body ms-0" onClick={() => { logout(); }}>
                                <icon.Power size={16} />
                                <span className="mx-2">{translate(`logout_${lang}`)}</span>
                            </p>
                        </li>
                    </ul>
                </div>
            )
            setNav(
                <ul className="navbar-nav link auth">
                    {res.data.role === "admin" || res.data.role === "superuser" ?
                        <>
                            <li className="nav-item link">
                                <Link to="/items" className="btn btn-sm btn-primary">{translate(`items_${lang}`)}</Link>
                            </li>
                            <li className="nav-item link">
                                <Link to="/stores" className="btn btn-sm btn-primary">{translate(`stores_${lang}`)}</Link>
                            </li>
                            {/* <li className="nav-item link">
                                <Link to="/finance/employees" className="btn btn-sm btn-primary">{translate(`employees_${lang}`)}</Link>
                            </li> */}
                            <li className="nav-item link">
                                <Link to="/customers" className="btn btn-sm btn-primary">{translate(`customers_${lang}`)}</Link>
                            </li>
                            {/* <li className="nav-item link">
                                <Link to="/paymentdetailcompanies" className="btn btn-sm btn-primary">{'کشف حيساب - شريكات'}</Link>
                            </li> */}
                            <li className="nav-item link">
                                <Link to="/paymentdetailcustomers" className="btn btn-sm btn-primary">{'Customer Payment Balance'}</Link>
                            </li>
                        </> : null
                    }
                </ul >
            )
        })
        $('[data-toggle="horizontal-menu-toggle"]').on("click", function () {
            $(".horizontal-menu .bottom-navbar").toggleClass("header-toggled");
        });

        if ($('.sidebar .sidebar-body').length) {
            new window.PerfectScrollbar('.sidebar-body');
        }

        $(document).on('click touchstart', (e) => {
            e.stopPropagation();
            if (!$(e.target).closest('.sidebar-toggler').length) {
                var sidebar = $(e.target).closest('.sidebar').length;
                var sidebarBody = $(e.target).closest('.sidebar-body').length;
                if (!sidebar && !sidebarBody) {
                    if ($('body').hasClass('sidebar-open')) {
                        $('body').removeClass('sidebar-open');
                    }
                }
            }
        });

        $(window).on('scroll', () => {
            if (window.matchMedia('(min-width: 992px)').matches) {
                var header = $('.horizontal-menu');
                if ($(window).scrollTop() >= 60) {
                    $(header).addClass('fixed-on-scroll');
                } else {
                    $(header).removeClass('fixed-on-scroll');
                }
            }
        });

        $('.sidebar .sidebar-body').on('hover', () => {
            $('body').addClass('overflow-hidden');
        }, () => {
            $('body').removeClass('overflow-hidden');
        });
    }, []);

    return (
        <nav className="navbar">
            <a href="/" className="sidebar-toggler" onClick={(e) => {
                e.preventDefault();
                $('.sidebar-header .sidebar-toggler').toggleClass('active not-active');
                if (window.matchMedia('(min-width: 992px)').matches) {
                    $('body').toggleClass('sidebar-folded');
                } else if (window.matchMedia('(max-width: 991px)').matches) {
                    $('body').toggleClass('sidebar-open');
                }
            }}>
                <icon.Menu size={20} />
            </a>
            <div className="navbar-content">
                {nav}
                <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                        <p className="nav-link dropdown-toggle" id="languageDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                            {
                                lang === 'ar' ? <img src="../../../../assets/img/iq.png" className="flag-icon" /> : lang === 'en' ? <img src="../../../../assets/img/uk.png" className="flag-icon" /> : <img src="../../../../assets/img/krd.png" className="flag-icon" />
                            }
                        </p>
                        <div className="dropdown-menu p-0" aria-labelledby="profileDropdown">
                            <ul className="list-unstyled p-1">
                                <li className="dropdown-item py-2">
                                    <p type="button" className="text-body ms-0" onClick={() => { localStorage.setItem('lang', 'krd'); window.location.reload(); }} >
                                        <img src="../../../../assets/img/krd.png" className="flag-icon" />
                                        <span className="mx-2">{translate(`kurdi`)}</span>
                                    </p>
                                </li>
                                <li className="dropdown-item py-2">
                                    <p type="button" className="text-body ms-0" onClick={() => { localStorage.setItem('lang', 'ar'); window.location.reload(); }} >
                                        <img src="../../../../assets/img/iq.png" className="flag-icon" />
                                        <span className="mx-2">{translate(`arabic`)}</span>
                                    </p>
                                </li>
                                <li className="dropdown-item py-2">
                                    <p type="button" className="text-body ms-0" onClick={() => { localStorage.setItem('lang', 'en'); window.location.reload(); }} >
                                        <img src="../../../../assets/img/uk.png" className="flag-icon" />
                                        <span className="mx-2">{translate(`english`)}</span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <p className="nav-link dropdown-toggle" id="profileDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <icon.User size={20} />
                        </p>
                        {userInfo}
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;