import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import commaNumber from "comma-number";

const AvailableProducts = ({ xls, icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer" && role !== "operations" && role !== "productions" && role !== "sales") {
        window.location.assign('/')
    }
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const getData = async (from_date, to_date) => {
        let Data;
        if (from_date && to_date) {
            const { data } = await axios.get(`/production/available?from_date=${from_date}&to_date=${to_date}`);
            Data = data
        } else {
            const { data } = await axios.get(`/production/available`);
            Data = data
        }
        setData(Data);
        if (Data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 2, 8]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: []
                                },

                            ]
                        })
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    }


    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new window.bootstrap.Tooltip(tooltipTriggerEl)
        })
    }, []);

    const [service, setService] = useState([]);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`available_products_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`available_products_${lang}`)}</h6>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <label htmlFor="from_date" className="col-12 form-label">{translate(`from_date_${lang}`)}:</label>
                                    <input type="date" className="form-control form-control-sm" id="from_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="to_date" className="col-12 form-label">{translate(`to_date_${lang}`)}:</label>
                                    <input type="date" className="form-control form-control-sm" id="to_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="sumbit" className="col-12 form-label">&nbsp;</label>
                                    <button type="button" className="btn btn-sm btn-inverse-dark"
                                        onClick={() => {
                                            const from_date = $("#from_date").val();
                                            const to_date = $("#to_date").val();
                                            getData(from_date, to_date)
                                        }}
                                    >{translate(`filter_${lang}`)}</button>
                                </div>
                            </div>
                            <table className="table table-sm dt-tbl print mb-3" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{translate(`product_${lang}`)}</th>
                                        <th>{translate(`store_${lang}`)}</th>
                                        <th>{translate(`sqm_${lang}`)}</th>
                                        <th>{translate(`productions_${lang}`)}</th>
                                        <th>{translate(`receipt_${lang}`)}</th>
                                        <th>{translate(`total_cost_${lang}`)}</th>
                                        <th>{translate(`attachment_${lang}`)}</th>
                                        <th>{translate(`receipt_${lang}`)}</th>
                                        <th>{translate(`note_${lang}`)}</th>
                                        <th>{translate(`date_${lang}`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={item.id}>
                                            <th>{item.id}</th>
                                            <td>{item[`product_name_${lang}`]}</td>
                                            <td>{item[`store_name`]}</td>
                                            <td>{commaNumber(item.square_meter)} $</td>
                                            <td>{commaNumber(item.product_cost)} $</td>
                                            <td>{commaNumber(item.service_cost)} $</td>
                                            <td>{commaNumber(item.total_cost)} $</td>
                                            <td>{item.attachment ? <a href={`/${item.attachment}`} className="text-dark" target="_blank" rel="noreferrer"><icon.Eye size="20" /></a> : <icon.EyeOff size="20" className="text-dark" />}</td>
                                            <td>
                                                <span type="button" className="badge bg-light text-dark mx-1" data-bs-toggle="modal" data-bs-target={`#service-list-${item.id}`} onClick={() =>
                                                    axios.get(`/service/list/${item.id}`).then(res => {
                                                        setService(res.data);
                                                    })}>
                                                    <icon.Search size={16} />
                                                </span>
                                                <div className="modal fade" id={`service-list-${item.id}`} tabIndex={-1} aria-labelledby={`service-list-${item.id}`} aria-hidden="true">
                                                    <div className="modal-dialog modal-xl">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title"> {translate(`receipt_${lang}`)} </h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                            </div>
                                                            <div className="modal-body">
                                                                <table className="table table-borderless table-sm">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>{translate(`type_${lang}`)}</th>
                                                                            <th>{translate(`group_${lang}`)}</th>
                                                                            <th>{translate(`cost_${lang}`)}</th>
                                                                            <th>{translate(`note_${lang}`)}</th>
                                                                            <th>{translate(`date_${lang}`)}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {service.map((item, index) => (
                                                                            <tr key={item.id}>
                                                                                <th>{index + 1}</th>
                                                                                <td>{item[`service_name_${lang}`]}</td>
                                                                                <td>{item[`group_name_${lang}`]}</td>
                                                                                <td>{commaNumber(item.cost)} {item.symbol}</td>
                                                                                <td>{item.note ? item.note : "-"}</td>
                                                                                <td>{new Date(item.created).toLocaleDateString()}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-primary" onClick={() => {
                                                                    window.$(`#service-list-${item.id}`).modal('hide');
                                                                    navigate(`/services/list/${item.id}`);
                                                                }} > {translate(`update_${lang}`)} <icon.ArrowUpLeft size={18} /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span type="button" data-bs-toggle="tooltip" data-bs-placement="top" title={item.note ? item.note : "-"}>
                                                    {item.note ? item.note.slice(0, 20) + '...' : "-"}
                                                </span>
                                            </td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button className="btn btn-light btn4xls" onClick={xls}>{translate(`ex2excel_${lang}`)}</button>
                            <button className="btn btn-light btn4pdf mx-2" onClick={() => window.print()}>{translate(`ex2pdf_${lang}`)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AvailableProducts;