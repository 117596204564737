import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import commaNumber from 'comma-number';
import axios from "axios";
import $ from "jquery";

const ReturnsInvoice = ({ translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer" && role !== "operations" && role !== "orders") {
        window.location.assign('/')
    }
    const returnId = useParams().id

    const [returns, setReturns] = useState([]);
    const getReturns = async () => {
        const { data } = await axios.get(`/return/invlist/${returnId}`);
        setReturns(data);
    }

    const [returnsItem, setReturnsItem] = useState([]);
    const getReturnsItem = async () => {
        const { data } = await axios.get(`/return/invitem/${returnId}`);
        setReturnsItem(data);
    }

    useEffect(() => {
        getReturns();
        getReturnsItem();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        $('.py-4').hide();
        $('.dt-tbl').show();
        // eslint-disable-next-line
    }, [returnId]);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`invoice_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        {returnsItem.length ?
                            <div className="card-body print">
                                <div className="container-fluid d-flex justify-content-between">
                                    <div className="col-lg-3 ps-0">
                                        <a href="/" className="noble-ui-logo d-block mt-3">Lion <span>Land Co.</span></a>
                                        <p className="mt-1 mb-1">{translate(`company_name_${lang}`)}<br /> <b>{translate(`company_text_${lang}`)}</b></p>
                                        <small className="text-muted">{translate(`address_${lang}`)}: {translate(`company_address_${lang}`)}</small>
                                        <h5 className="mt-5 mb-2 text-muted">{translate(`company_${lang}`)}</h5>
                                        <p>{returns[`customer_name_${lang}`]}</p>
                                    </div>
                                    <div className="col-lg-3 pe-0">
                                        <h4 className="fw-bolder text-uppercase text-end mt-4 mb-2">{translate(`invoice_${lang}`)}</h4>
                                        <h6 className="text-end mb-5 pb-4">{translate(`invoice_no_${lang}`)}-{returns.id}</h6>
                                        <p className="text-end mb-1">{translate(`total_${lang}`)}</p>
                                        <h4 className="text-end fw-normal">{commaNumber(returnsItem.reduce((total, item) => total + item.total_price, 0).toFixed(2),)} {returns && returns.symbol}</h4>
                                        <h6 className="mb-0 mt-3 text-end fw-normal mb-2"><span className="text-muted">{translate(`date_${lang}`)} :</span> {new Date(returns.created).toLocaleDateString()}</h6>
                                    </div>
                                </div>
                                <div className="container-fluid mt-5 w-100">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{translate(`group_${lang}`)}</th>
                                                    <th>{translate(`item_${lang}`)}</th>
                                                    <th>{translate(`qty_${lang}`)}</th>
                                                    <th>{translate(`height_${lang}`)}</th>
                                                    <th>{translate(`width_${lang}`)}</th>
                                                    <th>{translate(`weight_${lang}`)}</th>
                                                    <th>{translate(`price_${lang}`)}</th>
                                                    <th>{translate(`total_price_${lang}`)}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {returnsItem.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item[`group_name_${lang}`]}</td>
                                                        <td>{item[`item_name_${lang}`]}</td>
                                                        <td>{item.quantity ? item.quantity : '-'} </td>
                                                        <td>{item.height ? item.height : '-'} </td>
                                                        <td>{item.width ? item.width : '-'}</td>
                                                        <td>{item.weight ? item.weight : '-'}</td>
                                                        <td>{commaNumber(item.price)}</td>
                                                        <td>{commaNumber(item.total_price)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="container-fluid mt-5 w-100">
                                    <div className="row">
                                        <div className="col-xl-6 ms-auto px-5 mt-4">
                                            <div className="table-responsive">
                                                <table className="table table-sm">
                                                    <tbody>
                                                        <tr >
                                                            <td className="text-bold-800">{translate(`area${lang}`)}</td>
                                                            <td className="text-bold-800 text-end">{commaNumber(returnsItem.reduce((total, item) => total + (item.height * item.width), 0).toFixed(2))} {translate(`sqm_${lang}`)}</td>
                                                        </tr>
                                                        <tr className="bg-light">
                                                            <td className="text-bold-800">{translate(`qty_${lang}`)}</td>
                                                            <td className="text-bold-800 text-end">{commaNumber(returnsItem.reduce((total, item) => total + item.quantity, 0).toFixed(2))} {translate(`qty_text_${lang}`)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-bold-800"> {translate(`weight_${lang}`)}</td>
                                                            <td className="text-bold-800 text-end">{commaNumber(returnsItem.reduce((total, item) => total + (item.weight), 0).toFixed(2))} {translate(`kg_${lang}`)}</td>
                                                        </tr>
                                                        <tr className="bg-dark text-white">
                                                            <td className="text-bold-800">{translate(`total_${lang}`)}</td>
                                                            <td className="text-bold-800 text-end"> {commaNumber(returnsItem.reduce((total, item) => total + item.total_price, 0).toFixed(2),)} {returns && returns.symbol}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </div >
        </div >
    );
}

export default ReturnsInvoice;