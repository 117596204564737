/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'
import commaNumber from 'comma-number';

const NewWaste = ({ icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "operations" && role !== "sales") {
        window.location.assign('/')
    }
    const navigate = useNavigate();
    const wasteId = JSON.parse(localStorage.getItem('wasteId'));
    useEffect(() => {
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        if (wasteId) {
            $('#sales').hide();
            $('#sale_item').show();
        } else {
            $('#sales').show();
            $('#sale_item').hide();
        }
    }, [wasteId]);

    const [buyer, setBuyer] = useState([]);
    const getBuyer = async () => {
        const { data } = await axios.get('/order/list');
        setBuyer(data.map((item) => {
            return {
                value: item.id,
                label: `${item[`customer_name`]} - ${item.id}`,
            };
        }));
    }
 
    const [store, setStore] = useState([]);
    const getStore = async () => {
        const { data } = await axios.get('/store/list');
        setStore(data.map((item) => {
            return {
                value: item.id,
                label: item[`name`],
            };
        }));
    }

    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    const [items, setItems] = useState([]);
    const getItems = async () => {
        const { data } = await axios.get('/item/avaliablelist');
        setItems(data.map((item) => {
            return {
                value: `${item.order_id}-${item.item_id}`,
                label: `Item : ${item[`item_name`]} - Kg.Balance :${commaNumber(item.kg_balance.toFixed(2))} - Company: ${item['company']} - Order Price :${item['order_price']}`
            };
        }));
    }

    const itemSelected = (e) => {
        axios.post(`/wasteItem/add`, { wasteId: wasteId, item_id: e.value }).then(res => {
            if (isNaN(res.data)) {
                toast.error(res.data, {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                getSaleItem();
            }
        })
    }

    const [saleItem, setSaleItem] = useState([]);
    const getSaleItem = async () => {
        const { data } = await axios.get(`/wasteItem/list/${JSON.parse(localStorage.getItem('wasteId'))}`);
        setSaleItem(data);
    }

    useEffect(() => {
        getItems();
        getStore();
        getBuyer(); 
        getSaleItem();
    }, []);


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`add_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card" id="sales" style={{ display: "none" }}>
                    <div className="card">
                        <form className="form-sample" onSubmit={(e) => {
                            e.preventDefault();
                            const formData = new FormData(e.target);
                            const data = Object.fromEntries(formData);
                            axios.post(`/waste/add`, data).then(res => {
                                if (isNaN(res.data)) {
                                    toast.error(res.data, {
                                        position: "top-left",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                } else {
                                    localStorage.setItem('wasteId', JSON.stringify(res.data));
                                    $('#sales').hide();
                                    $('#sale_item').show();
                                    getSaleItem();
                                }
                            });
                        }}>
                            <div className="card-body">
                                <h5 className="card-title">{translate(`add_${lang}`)}</h5>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <label className="form-label"> {translate(`buyer_${lang}`)}</label>
                                                <Select options={buyer} name="order_id" placeholder={translate(`buyer_${lang}`)} required />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className="form-label"> {translate(`store_${lang}`)}</label>
                                                <Select options={store} name="store_id" placeholder={translate(`store_${lang}`)} required />
                                            </div>

                                            <div className="col-md-12 mb-3">
                                                <label className="form-label">{translate(`note_${lang}`)}</label>
                                                <textarea className="form-control" name="description" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="col-md-12 mb-3">
                                            <label className="form-label"> {translate(`attachment_${lang}`)}</label>
                                            <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" />
                                            <input type="hidden" name="attachment" defaultValue={selectedFile} />
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label className="form-label"> {translate(`date_${lang}`)}</label>
                                            <input type="date" className="form-control" name="created" defaultValue={new Date().toISOString().split('T')[0]} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button type="submit" className="btn btn-primary">{translate(`next_${lang}`)}
                                    <icon.ArrowLeft className="mx-2 mt-1" />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-12 grid-margin stretch-card" id="sale_item" style={{ display: "none" }}>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title"> {translate(`damages_${lang}`)}</h5>
                            <div className="row">
                                <div className="col-md-4 mb-3 mt-2">
                                    <div className="input-group mb-3">
                                        <div className="col-12">
                                            <Select options={items} placeholder={translate(`select_product_${lang}`)} onChange={(e) => { itemSelected(e); }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <div className="table-responsive">
                                        <table className="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{translate(`item_${lang}`)}</th>
                                                    <th>{translate(`customers_${lang}`)}</th>
                                                    <th>{translate(`kg_${lang}`)}</th>
                                                    <th>{translate(`price_${lang}`)}</th>
                                                    <th>{translate(`qty_${lang}`)}</th>
                                                    <th>{translate(`amount_${lang}`)}</th>
                                                    <th>{translate(`options_${lang}`)}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {saleItem.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.id}</td>
                                                        <td>{item[`item_name`]}</td>
                                                        <td>{item[`customer_name`]}-{item.order_id}</td>
                                                        <td>
                                                            <input type="number" className="form-control" name="kg" step="any" defaultValue={item.kg} onChange={(e) => {
                                                                axios.put(`/wasteItem/update/${item.id}`, { kg: e.target.value }).then(res => {
                                                                    getSaleItem();
                                                                });
                                                            }} />
                                                        </td>
                                                        <td>
                                                            <input type="number" className="form-control" name="price" step="any" defaultValue={item.price} onChange={(e) => {
                                                                axios.put(`/wasteItem/update/${item.id}`, { price: e.target.value }).then(res => {
                                                                    getSaleItem();
                                                                });
                                                            }} />
                                                        </td>
                                                        <td>
                                                            <input type="number" className="form-control" name="unit" step="any" defaultValue={item.unit} onChange={(e) => {
                                                                axios.put(`/wasteItem/update/${item.id}`, { unit: e.target.value }).then(res => {
                                                                    getSaleItem();
                                                                });
                                                            }} />
                                                        </td>
                                                        <td>{commaNumber(item.amount.toFixed(2))}  </td>
                                                        <td>
                                                            <span type="button" className="badge bg-danger" onClick={() => {
                                                                axios.delete(`/wasteItem/list/${item.id}`).then(res => {
                                                                    getSaleItem();
                                                                });
                                                            }}>
                                                                <icon.Trash />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row mt-3">
                                <div className="col-md-5 mb-3"> 
                                   
                                </div>
                                <div className="col-md-2 mb-3"></div>
                                <div className="col-md-5 mb-3">
                                    <h5 className="card-title">{translate(`summery_sale_${lang}`)}</h5>
                                    <div className="table-responsive">
                                        <table className="table table-sm">
                                            <tbody>
                                                <tr className="bg-dark text-white">
                                                    <td className="text-bold-800">{translate(`total_${lang}`)}</td>
                                                    <td className="text-bold-800 text-end"> {commaNumber(saleItem.reduce((total, item) => total + item.amount, 0).toFixed(2))}  </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3 mt-3 float-end">
                                        <button type="submit" className="btn btn-success" onClick={() => {
                                            localStorage.removeItem('currency');
                                            localStorage.removeItem('wasteId');
                                            navigate('/waste/list');
                                        }} >
                                            {translate(`add_${lang}`)}
                                            <icon.Save className="mx-2 mt-1" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewWaste;