import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import commaNumber from 'comma-number';
import axios from "axios";
import $ from "jquery";

const CustomInvoice = ({ translate, lang, role }) => {
    const customer_id = useParams().id
    const [invoice, setInvoice] = useState([]);
    const [info, setInfo] = useState({});
    const getInvoice = async () => {
        const { data } = await axios.get(`/customer/custom-inv/${customer_id}`);
        setInvoice(data);
        const { data: info } = await axios.get(`/customer/custom-info/${customer_id}`);
        setInfo(info);
    }

    useEffect(() => {
        getInvoice();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        $('.py-4').hide();
        $('.dt-tbl').show();
        // eslint-disable-next-line
    }, [customer_id]);

    const totalsByCurrency = invoice.reduce((totals, item) => {
        const symbol = item.symbol;
        const total = item.amount;
        const invType = item.inv_type;

        if (totals.hasOwnProperty(symbol)) {
            totals[symbol].total += total;
        } else {
            totals[symbol] = {
                total: total,
            };
        }

        if (totals[symbol].hasOwnProperty(invType)) {
            totals[symbol][invType] += total;
        } else {
            totals[symbol][invType] = total;
        }

        return totals;
    }, {});

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`invoice_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        {invoice.length ?
                            <div className="card-body print">
                                <div className="container-fluid d-flex justify-content-between">
                                    <div className="col-lg-3 ps-0">
                                        <a href="/" className="noble-ui-logo d-block mt-3">Lion <span>Land Co</span></a>
                                        <p className="mt-1 mb-1">{translate(`company_name_${lang}`)}<br /> <b>{translate(`company_text_${lang}`)}</b></p>
                                        <small className="text-muted">{translate(`address_${lang}`)}: {translate(`company_address_${lang}`)}</small>
                                        <h5 className="mt-5 mb-2 text-muted">{translate(`buyer_${lang}`)}/{translate(`company_${lang}`)}</h5>
                                        <p>{info[`full_name_${lang}`]}</p>
                                    </div>
                                    <div className="col-lg-3 pe-0">
                                        <h4 className="fw-bolder text-uppercase text-end mt-4 mb-2">{translate(`invoice_${lang}`)}</h4>
                                        <h6 className="text-end mb-5 pb-4">{translate(`invoice_no_${lang}`)}-{info.id}</h6>
                                        <h6 className="mb-0 mt-3 text-end fw-normal mb-2"><span className="text-muted">{translate(`date_${lang}`)} :</span> {new Date(info.created).toLocaleDateString()}</h6>
                                    </div>
                                </div>
                                <div className="container-fluid mt-5 w-100">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{translate(`invoice_type_${lang}`)}</th>
                                                    <th>{translate(`note_${lang}`)}</th>
                                                    <th>{translate(`total_price_${lang}`)}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoice.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.inv_type === "income" ? translate(`income_${lang}`) : translate(`outcome_${lang}`)}</td>
                                                        <td>{commaNumber(item.note)}</td>
                                                        <td>{commaNumber(item.amount)}{item.symbol}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="container-fluid mt-5 w-100">
                                    <div className="row">
                                        <div className="col-xl-6 ms-auto px-5 mt-4">
                                            <div className="table-responsive">
                                                <table className="table table-sm">
                                                    <tbody>
                                                        <tr className="bg-light">
                                                            <td className="text-bold-800">{translate(`total_${lang}`)}</td>
                                                            {Object.entries(totalsByCurrency).map(([symbol, totals]) => (
                                                                <td key={symbol} className="text-bold-800 text-end">
                                                                    {commaNumber(totals.total)} {symbol} <br />
                                                                    {Object.entries(totals).map(([invType, amount]) => (
                                                                        invType !== 'total' && (
                                                                            <span key={invType}>
                                                                                {invType === "income" ? translate(`income_${lang}`) : translate(`outcome_${lang}`)} <br />
                                                                            </span>
                                                                        )
                                                                    ))}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </div >
        </div >
    );
}



export default CustomInvoice;