import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import commaNumber from "comma-number";
import Swal from "sweetalert2";
import Select from "react-select";

const List = ({ xls, icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer" && role !== "operations" && role !== "orders") {
        window.location.assign('/');
    }
    const [data, setData] = useState([]);
    const getData = async (from_date, to_date) => {
        let Data;
        if (from_date && to_date) {
            const { data } = await axios.get(`/order/list?from_date=${from_date}&to_date=${to_date}`);
            Data = data
        } else {
            const { data } = await axios.get('/order/list');
            Data = data
        }
        setData(Data);
        if (Data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            order: [[0, 'desc']],
                            footerCallback: function () {
                                var api = this.api();
                                var intVal = function (i) {
                                    var cleanString = i.replace(/<\/?[^>]+(>|$)/g, "").replace(/[^0-9.]/g, '');
                                    var parsed = Number(cleanString);
                                    if (isNaN(parsed)) {
                                        return 0;
                                    }
                                    return parsed;
                                };

                                var currencySums = {
                                    "$": {
                                        amount: 0,
                                        totalCost: 0,
                                        prepaid: 0,
                                        amountPaid: 0,
                                        amountDue: 0
                                    },
                                    "د.ع": {
                                        amount: 0,
                                        totalCost: 0,
                                        prepaid: 0,
                                        amountPaid: 0,
                                        amountDue: 0
                                    }
                                };
                                var columns = [4, 5, 7, 8, 9];
                                var fields = ['amount', 'totalCost', 'prepaid', 'amountPaid', 'amountDue'];
                                columns.forEach((colIndex, fieldIndex) => {
                                    api.column(colIndex).data().each(function (value, index) {
                                        var currency = value.includes("د.ع") ? "د.ع" : "$";
                                        currencySums[currency][fields[fieldIndex]] += intVal(value);
                                    });
                                });
                                fields.forEach((field, index) => {
                                    var html = Object.entries(currencySums).map(([currency, sums]) => {
                                        return `${commaNumber(sums[field])} ${currency}`;
                                    }).join("<br>");
                                    $(api.column(columns[index]).footer()).html(html);
                                });


                            },
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 2, 3, 13]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: [4, 5, 6, 7, 8, 9, 10, 11, 12]
                                },
                            ]

                        })
                    }
                    var api = window.$('.dt-tbl').DataTable();
                    api.on('draw.dt', function () {
                        api.columns().footer().each(function (footer) { $(footer).html('') });
                        var intVal = function (i) {
                            var cleanString = i.replace(/<\/?[^>]+(>|$)/g, "").replace(/[^0-9.]/g, '');
                            var parsed = Number(cleanString);
                            if (isNaN(parsed)) {
                                console.log("Could not parse: ", i);
                                return 0;
                            }
                            return parsed;
                        };
                        var currencySums = {
                            "$": {
                                amount: 0,
                                totalCost: 0,
                                prepaid: 0,
                                amountPaid: 0,
                                amountDue: 0
                            },
                            "د.ع": {
                                amount: 0,
                                totalCost: 0,
                                prepaid: 0,
                                amountPaid: 0,
                                amountDue: 0
                            }
                        };

                        var columns = [4, 5, 7, 8, 9];
                        var fields = ['amount', 'totalCost', 'prepaid', 'amountPaid', 'amountDue'];
                        columns.forEach((colIndex, fieldIndex) => {
                            api.column(colIndex).nodes().to$().filter(':visible').each(function (index, element) {
                                var value = $(element).text();
                                var currency = value.includes("د.ع") ? "د.ع" : "$";
                                currencySums[currency][fields[fieldIndex]] += intVal(value);
                            });
                        });

                        fields.forEach((field, index) => {
                            var html = Object.entries(currencySums).map(([currency, sums]) => {
                                return `${commaNumber(sums[field])} ${currency}`;
                            }).join("<br>");
                            $(api.column(columns[index]).footer()).html(html);
                        });
                    });


                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    }

    useEffect(() => {
        getSafe();
        getCurrency();
        getExpensesType();
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new window.bootstrap.Tooltip(tooltipTriggerEl)
        })
    }, []);


    const [safe, setsafe] = useState([]);
    const getSafe = async () => {
        const { data } = await axios.get("/finance/safe");
        setsafe(data.map((item) => {
            return {
                value: item.id,
                label: `${item.safe_code} - ${item[`safe_name`]}`
            };
        }));
    };

    const [currency, setCurrency] = useState([]);
    const getCurrency = async () => {
        const { data } = await axios.get('/currency');
        setCurrency(data.map((item) => {
            return {
                value: item.id,
                label: `${item.symbol} (${translate(`price_${lang}`)} ${item.rate})`,
                rate: item.rate,
            };
        }));
    }


    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    const [typeList, setTypeList] = useState([]);
    const [expensesType, setExpensesType] = useState([]);
    const getExpensesType = async () => {
        const { data } = await axios.get("/expenses/type");
        setExpensesType(data.map((item) => {
            return {
                value: item.id,
                label: `${item.code} - ${item.name}`
            };
        }));
        setTypeList(data);
    };


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`order_list_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`order_list_${lang}`)}</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    {(role === "admin" || role === "superuser" || role === "operations" || role === "orders") && (
                                        <Link to="/order/new" className="btn btn-sm btn-primary">
                                            <icon.Plus size="20" />
                                            <span className="ms-2">{translate(`new_order_${lang}`)}</span>
                                        </Link>
                                    )}
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <label htmlFor="from_date" className="col-12 form-label">{translate(`from_date_${lang}`)}:</label>
                                    <input type="date" className="form-control form-control-sm" id="from_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="to_date" className="col-12 form-label">{translate(`to_date_${lang}`)}:</label>
                                    <input type="date" className="form-control form-control-sm" id="to_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="sumbit" className="col-12 form-label">&nbsp;</label>
                                    <button type="button" className="btn btn-sm btn-inverse-dark"
                                        onClick={() => {
                                            const from_date = $("#from_date").val();
                                            const to_date = $("#to_date").val();
                                            getData(from_date, to_date)
                                        }}
                                    >{translate(`filter_${lang}`)}</button>
                                </div>
                            </div>
                            <table className="table table-sm dt-tbl print mb-3" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{translate(`invoice_no_${lang}`)}</th>
                                        <th>{translate(`company_${lang}`)}</th>
                                        <th>{translate(`safe_name_${lang}`)}</th>
                                        <th>{translate(`store_${lang}`)}</th>
                                        <th>{translate(`total_cost_${lang}`)}</th>
                                        <th>{translate(`discount_${lang}`)}</th>
                                        <th>{translate(`total_${lang}`)}</th>
                                        <th>{translate(`paid_${lang}`)}</th>
                                        <th>Paid-Expense</th>
                                        <th>{translate(`due_${lang}`)}</th>
                                        <th>{translate(`rate_${lang}`)}</th>
                                        <th>{translate(`note_${lang}`)}</th>
                                        <th>{translate(`attachment_${lang}`)}</th>
                                        <th>{translate(`date_${lang}`)}</th>
                                        <th>{translate(`options_${lang}`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.invoice_no}</td>
                                            <td>{item[`customer_name`]}</td>
                                            <td>{item.safe_name}</td>
                                            <td>{item[`store_name`]}</td>
                                            <td>{commaNumber(item.amount)}  </td>
                                            <td>{commaNumber(item.discount)} </td>
                                            <td>{commaNumber(item.after_discount)} </td>
                                            <td>{commaNumber(item.amount_paid)} </td>
                                            <td>{commaNumber(item.expense_amount)} </td>
                                            <td> {item.amount_due > 0 ? <span className="text-danger">{commaNumber(item.amount_due)}</span> : commaNumber(item.amount_due)} </td>
                                            <td>{commaNumber(item.rate)} {item.symbol}</td>
                                            <td>
                                                <span type="button" data-bs-toggle="tooltip" data-bs-placement="top" title={item.note ? item.note : "-"}>
                                                    {item.note ? item.note.slice(0, 20) + '...' : "-"}
                                                </span>
                                            </td>
                                            <td>{item.attachment ? <a href={`../../../${item.attachment}`} className="text-dark" target="_blank" rel="noreferrer"><icon.Eye size="20" /></a> : <icon.EyeOff size="20" className="text-dark" />}</td>
                                            <td>{new Date(item.order_date).toLocaleDateString()}</td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <icon.MoreVertical size={16} />
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        {(role === "admin" || role === "superuser" || role === "operations" || role === "orders") && (
                                                            <>
                                                                <Link to={`/order/edit/${item.id}`} className="dropdown-item">
                                                                    <icon.Edit size={16} />
                                                                    <span className="mx-2">{translate(`edit_${lang}`)}</span>
                                                                </Link>
                                                                <span type="button" data-bs-toggle="modal" data-bs-target={`#payment${item.id}`} className="dropdown-item">
                                                                    <icon.CreditCard size={16} />
                                                                    <span className="mx-2">Order-Partners</span>
                                                                </span>
                                                                <span type="button" className="dropdown-item" onClick={() => {
                                                                    Swal.fire({
                                                                        title: translate(`warning_${lang}`),
                                                                        text: translate(`warning_text_${lang}`),
                                                                        icon: 'warning',
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: '#3085d6',
                                                                        cancelButtonColor: '#d33',
                                                                        confirmButtonText: translate(`yes_${lang}`),
                                                                        cancelButtonText: translate(`no_${lang}`)
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            axios.delete(`/order/all/${item.id}`).then(res => {
                                                                                res.data !== "done" ? toast.error(res.data) : getData();
                                                                            })
                                                                        }
                                                                    })
                                                                }} >
                                                                    <icon.Trash size={16} />
                                                                    <span className="mx-2">{translate(`delete_${lang}`)}</span>
                                                                </span>
                                                            </>
                                                        )}
                                                        <Link to={`/order/invoice/${item.id}`} target={'_blank'} className="dropdown-item">
                                                            <icon.Printer size={16} />
                                                            <span className="mx-2">{translate(`print_${lang}`)}</span>
                                                        </Link>
                                                        <Link to={`/orders/listexpeneses/${item.id}`} target={'_blank'} className="dropdown-item">
                                                            <icon.List size={16} />
                                                            <span className="mx-2">{translate(`expenses_list_${lang}`)}</span>
                                                        </Link> 
                                                        <Link to={`/orders/listpartners/${item.id}`} target={'_blank'} className="dropdown-item">
                                                            <icon.List size={16} />
                                                            <span className="mx-2">Partner's List</span>
                                                        </Link> 
                                                    </div>
                                                </div>
                                                {/* Add Expenses  */}
                                                <div className="modal fade" id={`payment${item.id}`} tabIndex={-1} aria-labelledby={`payment${item.id}`} aria-hidden="true">
                                                    <div className="modal-dialog modal-md">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title"> Order-Partners</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                            </div>
                                                            <form className="form-sample" onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                data.order_id = item.id;
                                                                axios.post(`/order/partners`, data).then(res => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        window.$(`#payment${item.id}`).modal('hide')
                                                                        e.target.reset();
                                                                        getData();
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body"> 
                                                                    <div className="row mb-3">
                                                                        <div className="col-md-12">
                                                                            <label htmlFor="account_id" className="form-label">{translate(`safe_name_${lang}`)}</label>
                                                                            <Select options={safe} name="safe_id" placeholder={translate(`safe_name_${lang}`)} required />
                                                                        </div>
                                                                    </div>  
                                                                    <div className="mb-3">
                                                                        <label htmlFor="due" className="col-form-label">Profit Rate % </label>
                                                                        <input type="number" className="form-control" id='profit_rate' name="profit_rate" />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="description" className="col-form-label">{translate(`note_${lang}`)}:</label>
                                                                        <input type="text" className="form-control" id='description' name="description" />
                                                                    </div> 
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                        <button type="submit" className="btn btn-primary">{translate(`add_${lang}`)}</button>
                                                                    </div> 
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Set Currency Target  */}
                                                <div className="modal fade" id={`currency${item.id}`} tabIndex={-1} aria-labelledby={`currency${item.id}`} aria-hidden="true">
                                                    <div className="modal-dialog modal-md">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title"> {translate(`currency_${lang}`)} </h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                            </div>
                                                            <form className="form-sample" onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                data.order_id = item.id;
                                                                axios.put(`/order/expenses`, data).then(res => {
                                                                    if (isNaN(res.data)) {
                                                                        toast.error(res.data, {
                                                                            position: "top-left",
                                                                            autoClose: 3000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                        });
                                                                    } else {
                                                                        window.$(`#currency${item.id}`).modal('hide')
                                                                        e.target.reset();
                                                                        getData();
                                                                    }
                                                                });
                                                            }}>
                                                                <div className="modal-body">
                                                                    <div className="col-md-12 mb-3">
                                                                        <label className="form-label"> {translate(`currency_${lang}`)}</label>
                                                                        <Select options={currency} name="currency_id" placeholder={translate(`currency_${lang}`)} required onChange={(e) => {
                                                                            localStorage.setItem('currency', JSON.stringify(e.label));
                                                                            $('input[name="target_rate"]').val(e.rate);
                                                                        }} />
                                                                        <input type="hidden" name="target_rate" required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="due" className="col-form-label">{translate(`rate_${lang}`)}:</label>
                                                                        <input type="number" className="form-control" id='target_rate' name="target_rate" />
                                                                    </div>

                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{translate(`close_${lang}`)}</button>
                                                                        <button type="submit" className="btn btn-primary">{translate(`add_${lang}`)}</button>
                                                                    </div>

                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </tfoot>
                            </table>
                            <button className="btn btn-light btn4xls" onClick={xls}>{translate(`ex2excel_${lang}`)}</button>
                            <button className="btn btn-light btn4pdf mx-2" onClick={() => window.print()}>{translate(`ex2pdf_${lang}`)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default List;