import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import commaNumber from "comma-number";

const SafeOverview = ({ xls, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer" && role !== "operations") {
        window.location.assign('/')
    }
    const [report, setReport] = useState([]);
    const getReport = async () => {
        const res = await axios.get("/finance/report/safeoverview");
        setReport(res.data);
        if (res.data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            dom: 'Plfrtip',
                            // order: [[1, 'desc']],
                            // searchPanes: {
                            //     initCollapsed: true
                            // },
                            // columnDefs: [
                            //     {
                            //         searchPanes: {
                            //             show: true
                            //         },
                            //         targets: [1, 2, 3]
                            //     },
                            // ],
                            // footerCallback: function () {
                            //     var api = this.api();
                            //     var intVal = function (i) {
                            //         var cleanString = i.replace(/<\/?[^>]+(>|$)/g, "").replace(/[^0-9.]/g, '');
                            //         var parsed = Number(cleanString);
                            //         if (isNaN(parsed)) {
                            //             console.log("Could not parse: ", i);
                            //             return 0;
                            //         }
                            //         return parsed;
                            //     };
                            //     var currencySums = {
                            //         "$": {
                            //             credit: 0,
                            //             debt: 0,
                            //             balance: 0,
                            //         },
                            //         "IQD": {
                            //             credit: 0,
                            //             debt: 0,
                            //             balance: 0,
                            //         },
                            //         "GEL": {
                            //             credit: 0,
                            //             debt: 0,
                            //             balance: 0,
                            //         }
                            //     };

                            //     var columns = [1,2,3];
                            //     var fields = ['credit', 'debt', 'balance'];

                            //     // columns.forEach((colIndex, fieldIndex) => {
                            //     //     api.column(colIndex).data().each(function (value, index) {
                            //     //         var currency = value.includes("د.ع") ? "د.ع" : "$";
                            //     //         currencySums[currency][fields[fieldIndex]] += intVal(value);
                            //     //     });
                            //     // });
                            //     columns.forEach((colIndex, fieldIndex) => {
                            //         api.column(colIndex).data().each(function (value, index) {
                            //             // Determine the currency based on the value
                            //             var currency;
                            //             if (value.includes("IQD")) {
                            //                 currency = "IQD";
                            //             } else if (value.includes("$")) {
                            //                 currency = "$";
                            //             } else if (value.includes("GEL")) {
                            //                 currency = "GEL";
                            //             } else {
                            //                 // Default currency if none of the above match
                            //                 currency = "Unknown";
                            //             }

                            //             // Initialize currencySums[currency] if it doesn't exist
                            //             if (!currencySums[currency]) {
                            //                 currencySums[currency] = {};
                            //             }

                            //             // Initialize fields[currency] if it doesn't exist
                            //             if (!currencySums[currency][fields[fieldIndex]]) {
                            //                 currencySums[currency][fields[fieldIndex]] = 0;
                            //             }

                            //             // Add the value to the appropriate currency sum
                            //             currencySums[currency][fields[fieldIndex]] += intVal(value);
                            //         });
                            //     });

                            //     fields.forEach((field, index) => {
                            //         var html = Object.entries(currencySums).map(([currency, sums]) => {
                            //             if (field === 'balance') {
                            //                 var balanceValue = sums.debt - sums.credit;
                            //                 return `${translate(`balance_${lang}`)} ${commaNumber(balanceValue)} ${currency}`;
                            //             } else {
                            //                 return `${commaNumber(sums[field])} ${currency}`;
                            //             }
                            //         }).join("<br>");
                            //         $(api.column(columns[index]).footer()).html(html);
                            //     });
                            // },

                        })
                    }
                    // var api = window.$('.dt-tbl').DataTable();
                    // api.on('draw.dt', function () {
                    //     api.columns().footer().each(function (footer) { $(footer).html('') });
                    //     var intVal = function (i) {
                    //         var cleanString = i.replace(/<\/?[^>]+(>|$)/g, "").replace(/[^0-9.]/g, '');
                    //         var parsed = Number(cleanString);
                    //         if (isNaN(parsed)) {
                    //             console.log("Could not parse: ", i);
                    //             return 0;
                    //         }
                    //         return parsed;
                    //     };
                    //     var currencySums = {
                    //         "$": {
                    //             credit: 0,
                    //             debt: 0,
                    //             balance: 0,
                    //         },
                    //         "IQD": {
                    //             credit: 0,
                    //             debt: 0,
                    //             balance: 0,
                    //         },
                    //         "GEL": {
                    //             credit: 0,
                    //             debt: 0,
                    //             balance: 0,
                    //         }
                    //     };

                    //     var columns = [1,2,3];
                    //     var fields = ['credit', 'debt', 'balance'];
                    //     columns.forEach((colIndex, fieldIndex) => {
                    //         api.column(colIndex).nodes().to$().filter(':visible').each(function (index, element) {
                    //             var value = $(element).text();

                    //             // Determine the currency based on the value
                    //             var currency;
                    //             if (value.includes("IQD")) {
                    //                 currency = "IQD";
                    //             } else if (value.includes("$")) {
                    //                 currency = "$";
                    //             } else if (value.includes("GEL")) {
                    //                 currency = "GEL";
                    //             } else {
                    //                 // Default currency if none of the above match
                    //                 currency = "Unknown";
                    //             }

                    //             // Initialize currencySums[currency] if it doesn't exist
                    //             if (!currencySums[currency]) {
                    //                 currencySums[currency] = {};
                    //             }

                    //             // Initialize fields[currency] if it doesn't exist
                    //             if (!currencySums[currency][fields[fieldIndex]]) {
                    //                 currencySums[currency][fields[fieldIndex]] = 0;
                    //             }

                    //             // Add the value to the appropriate currency sum
                    //             currencySums[currency][fields[fieldIndex]] += intVal(value);
                    //         });
                    //     });

                    //     fields.forEach((field, index) => {
                    //         var html = Object.entries(currencySums).map(([currency, sums]) => {
                    //             if (field === 'balance') {
                    //                 var balanceValue = sums.debt - sums.credit;
                    //                 return `${translate(`balance_${lang}`)} ${commaNumber(balanceValue)} ${currency}`;
                    //             } else {
                    //                 return `${commaNumber(sums[field])} ${currency}`;
                    //             }
                    //         }).join("<br>");
                    //         $(api.column(columns[index]).footer()).html(html);
                    //     });

                    // })
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    useEffect(() => {
        getReport();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        // eslint-disable-next-line 
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`vouchers_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`voucher_${lang}`)}</h6>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-striped table-sm dt-tbl print mb-3" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>{translate(`safe_name_${lang}`)}</th>
                                        <th>{translate(`credit_${lang}`)}</th>
                                        <th>{translate(`debt_${lang}`)}</th>
                                        <th>{translate(`balance_${lang}`)}</th> 
                                        <th>Currency</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {report.map((item, index) => (
                                        <tr key={index}> 
                                            <td>{item[`safe_name`]}</td>
                                            <td>{commaNumber(item.credit)}</td>
                                            <td>{commaNumber(item.debit)}</td>
                                            <td>{commaNumber(item.balance)}</td> 
                                            <td>{item.symbol}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </tfoot>
                            </table>
                            <button className="btn btn-light btn4xls" onClick={xls}>{translate(`ex2excel_${lang}`)}</button>
                            <button className="btn btn-light btn4pdf mx-2" onClick={() => window.print()}>{translate(`ex2pdf_${lang}`)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SafeOverview;