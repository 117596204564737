import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import commaNumber from "comma-number";

const Dashboard = ({ icon, translate, lang, role }) => {
    const [sales, setSales] = useState([]);
    const [totalSalesAmount,settotalSalesAmount] = useState('');

    const getSales = async () => {
        const { data } = await axios.get('/sale/list');  
        setSales(data) 
    } 
    const [stats, setStats] = useState([]);
    useEffect(() => {
        const timer = setTimeout(() => {
            axios.get('/dashboard/status').then(res => {
                console.log(res.data);
                const order_amount = 0;
                const sale_amount = 0;
                setStats(
                    <div className="col-md-12">
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <span className="fw-semibold d-block mb-1"> {translate(`total_order_${lang}`)}</span>
                                                <h3 className="card-title mb-2"> {res.data['totalOrders'][0]['totalOrders'] ? commaNumber(res.data['totalOrders'][0]['totalOrders']) : 0} $ </h3>
                                            </div>
                                            <div className="col-md-3 mt-2">
                                                <span className="fw-semibold d-block mb-1"> <icon.Activity /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <span className="fw-semibold d-block mb-1"> {translate(`paid_order_${lang}`)}</span>
                                                <h3 className="card-title mb-2">{res.data['amount_paidOrders'][0]['amount_paidOrders'] ? commaNumber(res.data['amount_paidOrders'][0]['amount_paidOrders']) : 0} $</h3>
                                            </div>
                                            <div className="col-md-3 mt-2">
                                                <span className="fw-semibold d-block mb-1"> <icon.Download /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <span className="fw-semibold d-block mb-1">{translate(`due_order_${lang}`)}</span>
                                                <h3 className="card-title mb-2">{res.data['amount_dueOrders'][0]['amount_dueOrders'] ? commaNumber(res.data['amount_dueOrders'][0]['amount_dueOrders']) : 0} $</h3>
                                            </div>
                                            <div className="col-md-3 mt-2">
                                                <span className="fw-semibold d-block mb-1"> <icon.AlertTriangle /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <span className="fw-semibold d-block mb-1">{translate(`total_sale_${lang}`)}</span>
                                                <h3 className="card-title mb-2">{res.data['totalAmount'][0]['totalAmount'] ? commaNumber(res.data['totalAmount'][0]['totalAmount']) : 0} IQD </h3>
                                            </div>
                                            <div className="col-md-3 mt-2">
                                                <span className="fw-semibold d-block mb-1"> <icon.TrendingUp /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <span className="fw-semibold d-block mb-1"> {translate(`paid_sale_${lang}`)}</span>
                                                <h3 className="card-title mb-2">{res.data['amount_paid'][0]['amount_paid'] ? commaNumber(res.data['amount_paid'][0]['amount_paid']) : 0} IQD </h3>
                                            </div>
                                            <div className="col-md-3 mt-2">
                                                <span className="fw-semibold d-block mb-1"> <icon.ThumbsUp /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <span className="fw-semibold d-block mb-1"> {translate(`due_sale_${lang}`)}</span>
                                                <h3 className="card-title mb-2">{res.data['amount_due'][0]['amount_due'] ? commaNumber(res.data['amount_due'][0]['amount_due']) : 0} IQD </h3>
                                            </div>
                                            <div className="col-md-3 mt-2">
                                                <span className="fw-semibold d-block mb-1"> <icon.ThumbsDown /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                )
            })
            $('.nav-item').removeClass('active');
            $('.nav-item').each(function () {
                if ($(this).find('a').attr('href') === window.location.pathname) {
                    $(this).addClass('active');
                }
            });
            getSales();
          }, 1000); 
          return () => clearTimeout(timer);

   
        // eslint-disable-next-line
    }, []);


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`stats_${lang}`)}</li>
                </ol>
            </nav>
            {/* row center content */}
            <div className="row ">
                <div className="col-md-8">
                    <div className="row ">
                        {stats}
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    {/* {chartData.series && (
                                        <Chart options={chartData.options} series={chartData.series} type="bar" height={350} />
                                    )} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            {/* sales */}
                            <div className="d-flex justify-content-between align-items-baseline mb-2">
                                <h6 className="card-title mb-0">{translate(`sale_list_${lang}`)}</h6>
                            </div>
                            {sales.map((item, index) => (
                                <div className="d-flex flex-column" key={index}>
                                    <Link to={`/sale/invoice/${item.id}`} className="d-flex align-items-center border-bottom pb-3 mb-3">
                                        <div className="me-3">
                                            <icon.CheckCircle className="wd-35 text-dark" />
                                        </div>
                                        <div className="w-100">
                                            <div className="d-flex justify-content-between">
                                                <h6 className="text-body mb-2">{item.customer_name}  {commaNumber(item.amount)} IQD </h6>
                                                <p className="text-muted tx-12">{new Date(item.created).toLocaleDateString()}</p>
                                            </div>
                                            <p className="text-muted tx-13">{translate(`amount_paid_${lang}`)} {commaNumber(item.amount_paid)} IQD - {translate(`amount_due_${lang}`)} {commaNumber(item.amount_due)} IQD </p>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Dashboard;