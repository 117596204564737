import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import commaNumber from "comma-number";
import Swal from "sweetalert2"; 


const PartnersListOrderPage = ({ xls, icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer" && role !== "operations" && role !== "orders") {
        window.location.assign('/');
    }

    const order_id = useParams().id

    const [data, setData] = useState([]);
    const getData = async (from_date, to_date) => {
        let Data;
        const { data } = await axios.get(`/order/listpartners/${order_id}`);
        Data = data
        setData(Data);
        if (Data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            order: [[0, 'desc']],
                            footerCallback: function () {
                                var api = this.api();
                                var intVal = function (i) {
                                    var cleanString = i.replace(/<\/?[^>]+(>|$)/g, "").replace(/[^0-9.]/g, '');
                                    var parsed = Number(cleanString);
                                    if (isNaN(parsed)) {
                                        return 0;
                                    }
                                    return parsed;
                                };

                                var currencySums = {
                                    "$": {
                                        amount: 0,
                                        totalCost: 0,
                                        prepaid: 0,
                                        amountPaid: 0,
                                        amountDue: 0
                                    },
                                    "د.ع": {
                                        amount: 0,
                                        totalCost: 0,
                                        prepaid: 0,
                                        amountPaid: 0,
                                        amountDue: 0
                                    }
                                };
                                var columns = [4, 5, 7, 8, 9];
                                var fields = ['amount', 'totalCost', 'prepaid', 'amountPaid', 'amountDue'];
                                columns.forEach((colIndex, fieldIndex) => {
                                    api.column(colIndex).data().each(function (value, index) {
                                        var currency = value.includes("د.ع") ? "د.ع" : "$";
                                        currencySums[currency][fields[fieldIndex]] += intVal(value);
                                    });
                                });
                                fields.forEach((field, index) => {
                                    var html = Object.entries(currencySums).map(([currency, sums]) => {
                                        return `${commaNumber(sums[field])} ${currency}`;
                                    }).join("<br>");
                                    $(api.column(columns[index]).footer()).html(html);
                                });


                            },
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 2, 3, 13]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: [4, 5, 6, 7, 8, 9]
                                },
                            ]

                        })
                    }
                    var api = window.$('.dt-tbl').DataTable();
                    api.on('draw.dt', function () {
                        api.columns().footer().each(function (footer) { $(footer).html('') });
                        var intVal = function (i) {
                            var cleanString = i.replace(/<\/?[^>]+(>|$)/g, "").replace(/[^0-9.]/g, '');
                            var parsed = Number(cleanString);
                            if (isNaN(parsed)) {
                                console.log("Could not parse: ", i);
                                return 0;
                            }
                            return parsed;
                        };
                        var currencySums = {
                            "$": {
                                amount: 0,
                                totalCost: 0,
                                prepaid: 0,
                                amountPaid: 0,
                                amountDue: 0
                            },
                            "د.ع": {
                                amount: 0,
                                totalCost: 0,
                                prepaid: 0,
                                amountPaid: 0,
                                amountDue: 0
                            }
                        };

                        var columns = [4, 5, 7, 8, 9];
                        var fields = ['amount', 'totalCost', 'prepaid', 'amountPaid', 'amountDue'];
                        columns.forEach((colIndex, fieldIndex) => {
                            api.column(colIndex).nodes().to$().filter(':visible').each(function (index, element) {
                                var value = $(element).text();
                                var currency = value.includes("د.ع") ? "د.ع" : "$";
                                currencySums[currency][fields[fieldIndex]] += intVal(value);
                            });
                        });

                        fields.forEach((field, index) => {
                            var html = Object.entries(currencySums).map(([currency, sums]) => {
                                return `${commaNumber(sums[field])} ${currency}`;
                            }).join("<br>");
                            $(api.column(columns[index]).footer()).html(html);
                        });
                    });


                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    }

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new window.bootstrap.Tooltip(tooltipTriggerEl)
        })
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`order_list_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">List of Partners</h6>
                                </div>

                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row mb-3"> 
                                <div className="col-md-4">
                                    <label htmlFor="sumbit" className="col-12 form-label">&nbsp;</label>
                                    <button type="button" className="btn btn-sm btn-inverse-dark"
                                        onClick={() => {
                                            const from_date = $("#from_date").val();
                                            const to_date = $("#to_date").val();
                                            getData(from_date, to_date)
                                        }}
                                    >{translate(`filter_${lang}`)}</button>
                                </div>
                            </div>
                            <table className="table table-sm dt-tbl print mb-3" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{translate(`safe_name_${lang}`)}</th>
                                        <th>Profit-Rate</th>
                                        <th>{translate(`note_${lang}`)}</th>
                                        <th>{translate(`date_${lang}`)}</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.safe_name}</td>
                                            <td>{item.profit_rate} %</td>
                                            <td>{item.description}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                <span type="button" className="dropdown-item" onClick={() => {
                                                    Swal.fire({
                                                        title: translate(`warning_${lang}`),
                                                        text: translate(`warning_text_${lang}`),
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonColor: '#3085d6',
                                                        cancelButtonColor: '#d33',
                                                        confirmButtonText: translate(`yes_${lang}`),
                                                        cancelButtonText: translate(`no_${lang}`)
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            axios.delete(`/order/partners/${item.id}`).then(res => {
                                                                res.data !== "done" ? toast.error(res.data) : getData();
                                                            })
                                                        }
                                                    })
                                                }} >
                                                    <icon.Trash size={16} />
                                                    <span className="mx-2">{translate(`delete_${lang}`)}</span>
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </tfoot>
                            </table>
                            <button className="btn btn-light btn4xls" onClick={xls}>{translate(`ex2excel_${lang}`)}</button>
                            <button className="btn btn-light btn4pdf mx-2" onClick={() => window.print()}>{translate(`ex2pdf_${lang}`)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default PartnersListOrderPage;