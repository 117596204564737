import React, { useEffect, useState} from "react";
import axios from "axios";
import $ from "jquery";
import { Link,useParams } from "react-router-dom";
import { toast } from "react-toastify";
import commaNumber from "comma-number";
import Swal from "sweetalert2";
import Select from "react-select";



const ExpensesListOrderPage = ({ xls, icon, translate, lang, role }) => {
    if (role !== "admin" && role !== "superuser" && role !== "observer" && role !== "operations" && role !== "orders") {
        window.location.assign('/');
    }

    const order_id = useParams().id

    const [data, setData] = useState([]);
    const getData = async (from_date, to_date) => {
        let Data;
        const { data } = await axios.get(`/order/listexpenses/${order_id}`);
        Data = data
        setData(Data);
        if (Data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                            order: [[0, 'desc']],
                            footerCallback: function () {
                                var api = this.api();
                                var intVal = function (i) {
                                    var cleanString = i.replace(/<\/?[^>]+(>|$)/g, "").replace(/[^0-9.]/g, '');
                                    var parsed = Number(cleanString);
                                    if (isNaN(parsed)) {
                                        return 0;
                                    }
                                    return parsed;
                                };

                                var currencySums = {
                                    "$": {
                                        amount: 0,
                                        totalCost: 0,
                                        prepaid: 0,
                                        amountPaid: 0,
                                        amountDue: 0
                                    },
                                    "د.ع": {
                                        amount: 0,
                                        totalCost: 0,
                                        prepaid: 0,
                                        amountPaid: 0,
                                        amountDue: 0
                                    }
                                };
                                var columns = [4, 5, 7, 8, 9];
                                var fields = ['amount', 'totalCost', 'prepaid', 'amountPaid', 'amountDue'];
                                columns.forEach((colIndex, fieldIndex) => {
                                    api.column(colIndex).data().each(function (value, index) {
                                        var currency = value.includes("د.ع") ? "د.ع" : "$";
                                        currencySums[currency][fields[fieldIndex]] += intVal(value);
                                    });
                                });
                                fields.forEach((field, index) => {
                                    var html = Object.entries(currencySums).map(([currency, sums]) => {
                                        return `${commaNumber(sums[field])} ${currency}`;
                                    }).join("<br>");
                                    $(api.column(columns[index]).footer()).html(html);
                                });


                            },
                            dom: 'Plfrtip',
                            searchPanes: {
                                initCollapsed: true
                            },
                            columnDefs: [
                                {
                                    searchPanes: {
                                        show: true
                                    },
                                    targets: [1, 2, 3, 13]
                                },
                                {
                                    searchPanes: {
                                        show: false
                                    },
                                    targets: [4, 5, 6, 7, 8, 9]
                                },
                            ]

                        })
                    }
                    var api = window.$('.dt-tbl').DataTable();
                    api.on('draw.dt', function () {
                        api.columns().footer().each(function (footer) { $(footer).html('') });
                        var intVal = function (i) {
                            var cleanString = i.replace(/<\/?[^>]+(>|$)/g, "").replace(/[^0-9.]/g, '');
                            var parsed = Number(cleanString);
                            if (isNaN(parsed)) {
                                console.log("Could not parse: ", i);
                                return 0;
                            }
                            return parsed;
                        };
                        var currencySums = {
                            "$": {
                                amount: 0,
                                totalCost: 0,
                                prepaid: 0,
                                amountPaid: 0,
                                amountDue: 0
                            },
                            "د.ع": {
                                amount: 0,
                                totalCost: 0,
                                prepaid: 0,
                                amountPaid: 0,
                                amountDue: 0
                            }
                        };

                        var columns = [4, 5, 7, 8, 9];
                        var fields = ['amount', 'totalCost', 'prepaid', 'amountPaid', 'amountDue'];
                        columns.forEach((colIndex, fieldIndex) => {
                            api.column(colIndex).nodes().to$().filter(':visible').each(function (index, element) {
                                var value = $(element).text();
                                var currency = value.includes("د.ع") ? "د.ع" : "$";
                                currencySums[currency][fields[fieldIndex]] += intVal(value);
                            });
                        });

                        fields.forEach((field, index) => {
                            var html = Object.entries(currencySums).map(([currency, sums]) => {
                                return `${commaNumber(sums[field])} ${currency}`;
                            }).join("<br>");
                            $(api.column(columns[index]).footer()).html(html);
                        });
                    });


                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    }

    useEffect(() => {
        getSafe();
        getCurrency();
        getExpensesType();
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new window.bootstrap.Tooltip(tooltipTriggerEl)
        })
    }, []);


    const [safe, setsafe] = useState([]);
    const getSafe = async () => {
        const { data } = await axios.get("/finance/safe");
        setsafe(data.map((item) => {
            return {
                value: item.id,
                label: `${item.safe_code} - ${item[`safe_name`]}`
            };
        }));
    };
    const [currency, setCurrency] = useState([]);
    const getCurrency = async () => {
        const { data } = await axios.get('/currency');
        setCurrency(data.map((item) => {
            return {
                value: item.id,
                label: `${item.symbol} (${translate(`price_${lang}`)} ${item.rate})`,
                rate: item.rate,
            };
        }));
    }

    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    const [typeList, setTypeList] = useState([]);
    const [expensesType, setExpensesType] = useState([]);
    const getExpensesType = async () => {
        const { data } = await axios.get("/expenses/type");
        setExpensesType(data.map((item) => {
            return {
                value: item.id,
                label: `${item.code} - ${item.name}`
            };
        }));
        setTypeList(data);
    };


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`order_list_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">{translate(`order_list_${lang}`)}</h6>
                                </div>
                                
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row mb-3">
                                {/* <div className="col-md-4">
                                    <label htmlFor="from_date" className="col-12 form-label">{translate(`from_date_${lang}`)}:</label>
                                    <input type="date" className="form-control form-control-sm" id="from_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="to_date" className="col-12 form-label">{translate(`to_date_${lang}`)}:</label>
                                    <input type="date" className="form-control form-control-sm" id="to_date" defaultValue={new Date().toISOString().split('T')[0]} />
                                </div> */}
                                <div className="col-md-4">
                                    <label htmlFor="sumbit" className="col-12 form-label">&nbsp;</label>
                                    <button type="button" className="btn btn-sm btn-inverse-dark"
                                        onClick={() => {
                                            const from_date = $("#from_date").val();
                                            const to_date = $("#to_date").val();
                                            getData(from_date, to_date)
                                        }}
                                    >{translate(`filter_${lang}`)}</button>
                                </div>
                            </div>
                            <table className="table table-sm dt-tbl print mb-3" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{translate(`invoice_no_${lang}`)}</th>
                                        <th>{translate(`expenses_type_${lang}`)}</th>
                                        <th>{translate(`safe_name_${lang}`)}</th>
                                        <th>{translate(`total_${lang}`)}</th> 
                                        <th>{translate(`currency_${lang}`)}</th>
                                        <th>Ex-Amount</th>
                                        <th>{translate(`note_${lang}`)}</th> 
                                        <th>{translate(`attachment_${lang}`)}</th>
                                        <th>{translate(`date_${lang}`)}</th> 
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.invoice_no}</td>
                                            <td>{item.order_id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.safe_name}</td>
                                            <td>{commaNumber(item.amount)} {item.currency_header} </td> 
                                            <td>{commaNumber(item.rate_target)} {item.currency_target}</td>
                                            <td>{commaNumber(item.exchange_rate)} {item.currency_target} </td> 
                                            <td>{item.title}</td> 
                                            <td>{item.attachment ? <a href={`../../../${item.attachment}`} className="text-dark" target="_blank" rel="noreferrer"><icon.Eye size="20" /></a> : <icon.EyeOff size="20" className="text-dark" />}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </tfoot>
                            </table>
                            <button className="btn btn-light btn4xls" onClick={xls}>{translate(`ex2excel_${lang}`)}</button>
                            <button className="btn btn-light btn4pdf mx-2" onClick={() => window.print()}>{translate(`ex2pdf_${lang}`)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ExpensesListOrderPage;