import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import commaNumber from 'comma-number';
import axios from "axios";
import $ from "jquery";

const CompanyInvoice = ({ translate, lang, role }) => {
    const customer_id = useParams().id
    const currency_id = useParams().currency_id
    const [order, setOrder] = useState([]);
    const getOrder = async () => {

        const { data } = await axios.get(`/customer/summerycusttomer/${customer_id}/${currency_id}`);
        setOrder(data);
    }

    const [orderDetail, setOrderDetail] = useState([]);
    const getOrderDetail = async () => {
        const { data } = await axios.get(`/customer/summerycustomerdetails/${customer_id}/${currency_id}`);
        console.log(data);
        setOrderDetail(data);
    }

    useEffect(() => {
        getOrder();
        getOrderDetail();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        $('.py-4').hide();
        $('.dt-tbl').show();
        // eslint-disable-next-line
    }, [customer_id]);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{translate(`main_${lang}`)}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{translate(`invoice_${lang}`)}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body print">
                            <div className="container-fluid d-flex justify-content-between">
                                <div className="col-lg-3 ps-0">
                                <img src="/assets/img/logosss.png" style={{ marginLeft: '-60px' }}  className="ps-0" alt="Logo" height={'130'} width={'330'} />
                                    <p className="mt-1 mb-1">{translate(`company_name_${lang}`)}<br /> <b>{translate(`company_text_${lang}`)}</b></p>
                                    <small className="text-muted">Address: Erbil - Italian village 01- No.317</small>
                                    <h5 className="mt-5 mb-2 text-muted">Customer:</h5>
                                    <p>{order[`full_name`]}</p>
                                </div>
                                <div className="col-lg-3 pe-0">
                                    <h4 className="fw-bolder text-uppercase text-end mt-4 mb-2">کشف حيساب </h4>
                                    <h6 className="mb-0 mt-3 text-end fw-normal mb-2"><span className="text-muted">{translate(`date_${lang}`)} :</span> {new Date().toLocaleDateString()}</h6>
                                </div>
                            </div>
                            <div className="container-fluid mt-5 w-100">
                                <div className="table-responsive">
                                    <table className="table table-striped table-sm">
                                        <thead>
                                            <tr>
                                                <th>{translate(`invoice_${lang}`)}</th>
                                                <th>{translate(`amount_${lang}`)}</th>
                                                <th>{translate(`note_${lang}`)}</th>
                                                <th>{translate(`type_${lang}`)}</th>
                                                <th>{translate(`date_${lang}`)}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderDetail.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{commaNumber(item.invoice)} </td>
                                                    <td>{commaNumber(item.amount)} IQD </td>
                                                    <td>{commaNumber(item.description)}</td>
                                                    <td>{item.type == '0' ? <>
                                                        <span style={{ color: "red" }}>قرض</span>
                                                    </> : <>
                                                        <span style={{ color: "green" }}>مدفوع</span>
                                                    </>} </td>
                                                    <td>{new Date(item.created).toLocaleDateString()} </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="container-fluid mt-5 w-100">
                                <div className="row">
                                    <div className="col-xl-6 ms-auto px-5 mt-4">
                                        <div className="table-responsive">
                                            <table className="table table-sm">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-bold-800">{translate(`total_${lang}`)}</td>
                                                        <td className="text-bold-800 text-end">
                                                            {commaNumber(order.amount)} IQD
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-bold-800"> {translate(`paid_${lang}`)}</td>
                                                        <td className="text-bold-800 text-end">
                                                            {commaNumber(order.amount_paid)} IQD
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td className="text-bold-800">{translate(`due_${lang}`)}</td>
                                                        <td className="text-bold-800 text-end">
                                                            {commaNumber(order.amount_due)} IQD
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}



export default CompanyInvoice;